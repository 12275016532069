var columns = [{
  title: '奖项ID',
  dataIndex: 'id',
  key: 'id',
  width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '奖品名称',
  dataIndex: 'name',
  key: 'name',
  width: '15%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '奖品图片',
  dataIndex: 'img',
  key: 'img',
  width: '9%',
  scopedSlots: {
    customRender: 'img'
  }
}, {
  title: '奖券总量',
  dataIndex: 'ticket_num',
  key: 'ticket_num',
  width: '6%',
  scopedSlots: {
    customRender: 'num'
  }
}, {
  title: '奖券展示总量',
  dataIndex: 'ticket_show_num',
  key: 'ticket_show_num',
  width: '8%',
  scopedSlots: {
    customRender: 'show_num'
  }
}, {
  title: '当前已发券量',
  dataIndex: 'sended_ticket_num',
  key: 'ticketnum',
  width: '15%',
  scopedSlots: {
    customRender: 'ticketnum'
  }
}, {
  title: '个人奖券上限',
  dataIndex: 'limit_num',
  key: 'limit_num',
  width: '8%',
  scopedSlots: {
    customRender: 'limit_num'
  }
}, {
  title: '开始时间',
  dataIndex: 'start_time',
  key: 'start_time',
  width: '15%',
  scopedSlots: {
    customRender: 'start_time'
  }
}, {
  title: '结束时间',
  dataIndex: 'end_time',
  key: 'end_time',
  width: '15%',
  scopedSlots: {
    customRender: 'end_time'
  }
}, {
  title: '状态',
  dataIndex: 'state',
  key: 'state',
  width: '8%',
  scopedSlots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };